@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  @apply h-full;
  @apply m-0;
  @apply bg-slate-400;
}

.link {
    --link-underline-width: 0%;

    @apply cursor-pointer;

    background: linear-gradient(currentColor, currentColor, currentColor)
    bottom 0 left/ var(--link-underline-width, 0%) 1px no-repeat;

    @apply inline-block;
    transition: background-size 0.3s ease-in-out;
}

.link:hover {
    --link-underline-width: 100%;
}

.project-image-outline {
    @apply w-fit;
    @apply h-fit;

    @apply bg-slate-600;

    @apply rounded-xl;

    @apply m-4;
    @apply p-1.5;

    @apply transform;
    @apply duration-75;
    @apply hover:scale-105;
    @apply ease-in-out;
}

.project-image {
    @apply w-[256px];
    @apply min-w-[256px];
    @apply h-auto;

    @apply rounded-xl;
}

/*
following two classes taken from:
https://fonts.google.com/icons?selected=Material+Symbols+Outlined:favorite:FILL@0;wght@400;GRAD@0;opsz@24&icon.platform=web
*/

.material-symbols {
  font-variation-settings:
          'FILL' 1,
          'wght' 400,
          'GRAD' 0,
          'opsz' 24
}

.material-symbol-2xl {
    font-size: 30px !important;
    line-height: 36px !important;
}